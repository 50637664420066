import Layout from '../Layout/Layout'
import './Contact.css'
import { Form, Input, Button, Row, Col } from 'antd';
import { LinkedinOutlined, TwitterOutlined} from '@ant-design/icons';
import * as emailjs from 'emailjs-com'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation()

    const [form] = Form.useForm();

    const layout = {
        labelCol: {
          span: 4,
          sm: 8
        },
        wrapperCol: {
          span: 16,
          sm: 10
        },
      };

    const isRequiredText = t('validation_is_required');
    const isNotAValidEmailText = t('validation_is_invalid_email');
    const validateMessages = {
        required: '${label} ' + isRequiredText,
        types: {
          email: '${label} ' + isNotAValidEmailText
        },
      };

    const onFinish = (values) => {
        //send email!
          console.log(values);

          const templateParams = {
            from_name: values.user.name,
            to_name: 'Lynda Chiwetelu',
            message: values.user.message,
            reply_to: values.user.email
          }

          emailjs.send(
              process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParams, process.env.REACT_APP_EMAILJS_USER_ID)
              .then(response => {
            toast.success(t('contact_form_success_message'))
            form.resetFields()

          }).catch(error => toast.error(t('contact_form_error_message')))
    }
        
    return (
        <Layout>
            <ToastContainer position="top-center" />
            <div className='Contact'>
                <h1>{t('im_online_text')}</h1>
                <Row>
                    <Col span={8} xs={1} sm={8}></Col>
                    <Col span={4} xs={10} sm={4}>
                        <a href='https://www.linkedin.com/in/lyndachiwetelu' target='_blank' rel="noreferrer"> <LinkedinOutlined style={{fontSize:'60px'}}/></a>
                    </Col>
                    <Col span={4} xs={10} sm={4}>
                    <a href='https://twitter.com/lyndachiwetelu' target='_blank' rel="noreferrer"> <TwitterOutlined style={{fontSize:'60px'}}/> </a>
                    </Col>
                </Row>

                <h2>{t('send_message_text')}</h2>

             <Form {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages} form={form}>
                <Form.Item
                    name={['user', 'name']}
                    label={t('contact_form_field_name')}
                    rules={[
                    {
                        required: true,
                    },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={['user', 'email']}
                    label={t('contact_form_field_email')}
                    rules={[
                    {
                        type: 'email',
                        required: true,
                    },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name={['user', 'message']} label={t('contact_form_field_message')}
                    rules={[
                        {
                            required: true,
                        },
                        ]}
                >
                    <Input.TextArea rows='8'/>
                </Form.Item>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, sm:{offset: 5} }}>
                    <Button type="primary" htmlType="submit">
                    {t('contact_form_submit')}
                    </Button>
                </Form.Item>
                </Form>
            </div>
        </Layout> 
    )
}

export default Contact
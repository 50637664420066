import './Layout.css'
import {Row, Col, Layout as AntDLayout} from 'antd'
import { useTranslation } from 'react-i18next';

const Layout = ({children}) => {
  const { t } = useTranslation();

  const year = new Date().getFullYear()

  const { Footer } = AntDLayout
    return (
      <div className='Layout'>
        <Row className='LayoutContent'>
        <Col span={4} xs={1}></Col>
        <Col span={16} xs={22} className='Content'>
            {children}
        </Col>
        <Col span={4}></Col>
      </Row>
        <Footer className='Footer'>
            <p>{t('copyright')} © {year} Lynda Chiwetelu. {t('made_with_react')} ♥️</p>
        </Footer>
      </div>
    )
}

export default Layout
import Layout from '../Layout/Layout'
import './About.css'
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <div className='About'>
                <h1>{t('about_me_title')}</h1>

                <h3>{t('about_me_current')}</h3>
                <p>{t('about_me_current_text')}</p>

                <h3>{t('about_me_background')}</h3>
                <p>{t('about_me_background_text')}</p>


                <h3>{t('about_me_education')}</h3>
                <p>{t('about_me_education_text')}</p>

                <h3>{t('about_me_software')}</h3>
                <p> {t('about_me_software_text')} </p>

                <h3>{t('about_me_germany')}</h3>
                <p>{t('about_me_germany_text')}
                </p>

                <h3>{t('about_me_hobbies')}</h3>
                <p>{t('about_me_hobbies_text')}</p>
            </div>

        </Layout>
    )
}

export default About
import './NotFound.css';
import Layout from '../Layout/Layout'
import NotFoundPic from '../../assets/images/404.png'
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const { t } = useTranslation()

    return (
            <Layout>
                <div className='NotFound'>  
                    <h1>404!</h1> 
                    <h2>{t('not_found_message')}</h2>
                    <img src={NotFoundPic} className='NotFoundPic' alt='Not found pic'/>
                </div> 
            </Layout>   
    )
}

export default NotFound
import './Stack.css'
import Layout from '../Layout/Layout'
import ReactWordCloud from 'react-wordcloud'
import StackJson from './Stack.json'
import { useTranslation } from 'react-i18next';

const Stack = () => {

    const { t } = useTranslation()

    const options = {
        colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
        enableTooltip: true,
        deterministic: false,
        fontFamily: "impact",
        fontSizes: [5, 60],
        fontStyle: "normal",
        fontWeight: "normal",
        padding: 1,
        rotations: 3,
        rotationAngles: [0, 90],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 1000
      };

    return (
        <Layout>
            <div className='Stack'>
                <h1>{t('stack_title')}</h1> 
                <h2>{t('stack_description')}</h2>
                <ReactWordCloud options={options} words={StackJson} />
            </div>
        </Layout>
        
    )
}

export default Stack
import './Resume.css'
import Layout from '../Layout/Layout'
import ResumePdf from '../../assets/pdf/resume.pdf'
import { useTranslation } from 'react-i18next';

const Resume = () => {
    const { t } = useTranslation()
    return (
        <Layout>
             <div className='Resume'>
                <h1>{t('resume_title')}</h1>
                <a href={ResumePdf} target="_blank" rel="noreferrer"><p>{t('view_download_pdf')}</p></a>
                <div className="timeline">

                <div className="container left">
                        <div className="content">
                        <h2>{t('senior_software_eng_bitly')}</h2>
                        <h3>{t('july_2023_till_present')}</h3>
                        <ul>
                            
                        </ul>
                        </div>
                    </div>

                <div className="container right">
                        <div className="content">
                        <h2>{t('senior_software_kaufland')}</h2>
                        <h3>{t('march_2022_till_june_2023')}</h3>
                        <ul>
                            <li>{t('webApisNode')}</li>
                            <li>{t('batchProcessing')}</li>
                            <li>{t('grpcMicroservices')}</li>
                            <li>{t('extendedRestApis')}</li>
                            <li>{t('automatedTesting')}</li>
                            <li>{t('telemetricsMonitoring')}</li>
                        </ul>
                        </div>
                    </div>

                    <div className="container left">
                        <div className="content">
                        <h2>{t('php_developer_adition')}</h2>
                        <h3>{t('march_2020_till_present')}</h3>
                        <ul>
                            <li>{t('extendingSymfony')}</li>
                            <li>{t('extendingSoapApi')}</li>
                            <li>{t('activityLogAggregator')}</li>
                            <li>{t('improvingTestCoverage')}</li>
                            <li>{t('dockerizingApps')}</li>
                     
                        </ul>
                        </div>
                    </div>

                    <div className="container right">
                        <div className="content">
                        <h2>{t('backend_dev_about_you')}</h2>
                        <h3>{t('august_2018_august_2019')}</h3>
                        <ul>
                            <li>{t('scalable_checkout_systems')}</li>
                            <li>{t('automated_testing_backend')}</li>
                            <li>{t('integration_external_systems')}</li>
                        </ul>
                        </div>
                    </div>
                    <div className="container left">
                        <div className="content">
                        <h2>{t('fullstack_dev_brandbastion')}</h2>
                        <h3>{t('june_2017_july_2018')}</h3>
                        <ul>
                            <li>{t('facebook_insta_crawling')}</li>
                            <li>{t('refactoring_laravel_lumen')}</li>
                            <li>{t('content_classification')}</li>
                        </ul>
                        </div>
                    </div>
                    <div className="container right">
                        <div className="content">
                        <h2>{t('backend_dev_vision_dev')}</h2>
                        <h3>{t('jan_2017_may_2017')}</h3>
                        <ul>
                            <li>{t('rest_apis_ecommerce')}</li>
                            <li>{t('amazon_ebay_integration')}</li>
                        </ul>
                        </div>
                    </div>

                    <div className="container left">
                        <div className="content">
                        <h2>{t('lead_dev_hng')}</h2>
                        <h3>{t('jan_2016_dec_2016')}</h3>
                        <ul>
                            <li>{t('led_rewrite')}</li>
                            <li>{t('server_admin')}</li>
                            <li>{t('sprint_planning')}</li>
                            <li>{t('scrum_master')}</li>
                        </ul>
                        </div>
                    </div>

                    <div className="container right">
                        <div className="content">
                        <h2>{t('fullstack_dev_hng')}</h2>
                        <h3>{t('oct_2014_jan_2016')}</h3>
                        <ul>
                            <li>{t('refactoring_legacy')}</li>
                            <li>{t('building_new_features')}</li>
                            <li>{t('authentication_library')}</li>
                            <li>{t('rest_apis_php_node')}</li>
                        </ul>
                        </div>
                    </div>

                    <div className="container left">
                        <div className="content">
                        <h2>{t('fullstack_dev_mb')}</h2>
                        <h3>{t('oct_2016_july_2019')}</h3>
                        <ul>
                            <li>{t('quiz_site')}</li>
                            <li>{t('lamp_deployment')}</li>
                            <li>{t('open_cart')}</li>
                        </ul>
                        </div>
                    </div>

                    <div className="container right">
                        <div className="content">
                        <h2>{t('intern_web_dev')}</h2>
                        <h3>{t('aug_2013_dec_2013')}</h3>
                        <ul>
                            <li>{t('recruitment_website')}</li>
                            <li>{t('implement_designs')}</li>
                            <li>{t('jobs_ad_site')}</li>
                        </ul>
                        </div>
                    </div>

                    </div>
            </div>

        </Layout>
   )
}

export default Resume
import "./App.css";
import React, {useState} from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { Menu } from 'antd';
import { HomeOutlined, WomanOutlined, ProfileOutlined, CodeOutlined, PhoneOutlined, SwitcherFilled, MenuFoldOutlined} from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import Home from '../Home/Home'
import About from '../About/About'
import Resume from '../Resume/Resume'
import Stack from '../Stack/Stack'
import Contact from '../Contact/Contact'
import NotFound from "../NotFound/NotFound";

const { SubMenu } = Menu;

const App = () => {

  const { t, i18n } = useTranslation(['translation']);

  let currentUri = 'home'
  let pathname = window.location.pathname
  pathname = pathname.replace('/', '').toLowerCase()
  if (pathname !== '') {
    currentUri = pathname
  }
  
  const [currentMenu, setCurrentMenu] = useState(currentUri)
  const [currentLanguage, setCurrentLanguage] = useState('English')

  const handleClick = e => {
    setCurrentMenu(e.key)
  }

  const handleLanguageMenuClick = e => {
    i18n.changeLanguage(e.key)
    setCurrentLanguage(e.item.props.children[1])
  }
  
  return (
    <Router>
      <div className='MenuContainer'>
        <Menu
          onClick={handleClick}
          selectedKeys={[currentMenu]}
          mode="horizontal"
          className='Menu'
          overflowedIndicator={<MenuFoldOutlined />}
        >
          <Menu.Item key="home" icon={<HomeOutlined />}>
            <Link to="/">{t('home_menu')}</Link>
          </Menu.Item>
          <Menu.Item key="about" icon={<WomanOutlined />}>
            <Link to="/about">{t('about_menu')}</Link>
          </Menu.Item>
          {/* <Menu.Item key="resume" icon={<ProfileOutlined />}>
            <Link to="/resume">{t('resume_menu')}</Link>
          </Menu.Item> */}
          <Menu.Item key="stack" icon={<CodeOutlined />}>
          <Link to="/stack">{t('stack_menu')}</Link>
          </Menu.Item>
          <Menu.Item key="contact" icon={<PhoneOutlined />}>
            <Link to="/contact">{t('contact_menu')}</Link>
          </Menu.Item>

          <SubMenu key="SubMenu" icon={<SwitcherFilled />} title={t('select_language')}>
            <Menu.Item key="en" onClick={handleLanguageMenuClick}>{t('language_english')}</Menu.Item>
            <Menu.Item key="de" onClick={handleLanguageMenuClick}>{t('language_german')}</Menu.Item>
            <Menu.Item key="fr" onClick={handleLanguageMenuClick}>{t('language_french')}</Menu.Item>
        </SubMenu>

          <Menu.Item key='language'>
            {currentLanguage}
          </Menu.Item>
        </Menu>
        
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/resume">
            <Resume />
          </Route>
          <Route path="/stack">
            <Stack />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
